//Pruebas
// export const URL_BASE = "https://mercadeo.celuwebdev.com/apiHarinera/";

//Produccion
export const URL_BASE = "https://mercadeo.celuwebdev.com/apiHarineraPrd/";
// export const  URL_BASE = "http://localhost:36025/"
// export const URL_BASE = 'https://harinera-delvalle.celuwebcloud.com/Mercadeo/Sync/'
// export const URL_BASE = "http://64.239.7.197/HARINERASAP/APIHarinera/";
// export const URL_BASE = "https://emartwebapi.celuwebdev.com/apiHarinera/";

export const WSEMPRESA = "api/empresa/sucursal";
export const WSCATEGORIA = "api/categoria";
export const WSPRODUCTO = "api/producto";
export const WSSUGERIDO = "api/sugerido";
export const WSPEDIDO = "api/pedido";
export const WSHISTORIAL = "api/pedido/historial";
export const WSBANNERS = "api/Empresa/Banners";
export const WSLISTASDESCUENTO = "api/Cliente/ListasDescuentos";
export const WSGRUPOSDESCUENTO = "api/Cliente/GruposDescuentos";
export const WSLOGINCLIENTE = "api/Cliente/Login";
export const WSVALIDARCLIENTE = "api/Cliente/Validar";
export const WSREGISTROUSUARIO = "api/Cliente/Registro";
export const WSENVIARCODIGO = "api/Cliente/EnviarCodigo";
export const WSACTIVARCLIENTE = "api/Cliente/ActivarIP";
export const WSRECUPERACIONCLAVE = "api/Cliente/RecuperacionClave";
export const WSCARTERACLIENTE = "api/Cartera";
export const WSENCUESTA = "api/Encuesta";
export const WSACEPTARCOOKIES = "api/Cliente/ActivarCookies";
export const LASTNUMERODOC = "api/Pedido/lastNumeroDoc"

let empresa = JSON.parse(localStorage.getItem('empresa'));

let cantDecimales = 0

if(empresa){
    if(empresa.cant_decimales){
        cantDecimales = ! isNaN(empresa.cant_decimales) ? empresa.cant_decimales : 2
    }
}

export const getDate = () => {
    let d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear(),
        minute = '' + d.getMinutes(),
        second = '' + d.getSeconds();

    if (month.length < 2){
        month = '0' + month;
    } 
    if (day.length < 2) {
        day = '0' + day;
    }
    if (minute.length < 2) {
        minute = '0' + minute;
    }
    if (second.length < 2) {
        second = '0' + second;
    }

    let fecha = [year, month, day].join('-') + " " + [minute, second].join(':');

    return fecha;
}

export const copFormat = (nStr, decimals, milesSeparator, decimalSeparator) => {
      
    decimals = decimals || cantDecimales
    milesSeparator = milesSeparator || "."
    decimalSeparator = decimalSeparator || ","
 
    nStr = nStr ? parseFloat(nStr) : 0

    var x = nStr.toString().split('.');
    var x1 = x[0];
    var x2 = nStr.toFixed(decimals).split(".")[1]
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + milesSeparator + '$2');
    }
    return "$ " + x1 + (x2 ? decimalSeparator + x2 : "");
}


export const calcularDescuento = () => {
    let empresa = JSON.parse(localStorage.getItem('empresa'));
    
    // if (empresa.num_empresa == num_empresa_ramo) {

    //     let carrito = JSON.parse(localStorage.getItem(empresa.codigo_empresa)) || {};
        
    //     let listCarrito = JSON.parse(localStorage.getItem(empresa.codigo_empresa)) || {};
    //     let listasDescuento = JSON.parse(localStorage.getItem("listasDescuento"));
    //     let gruposDescuento = JSON.parse(localStorage.getItem("gruposDescuento"));
        
    //     let descuento_total = 0

    //     if (listasDescuento && gruposDescuento){
            
    //         listasDescuento.map((item_general, x)=> {

    //             if(Object.keys(listCarrito).length > 0 ){

    //                 let aplicarDescuento=[]

    //                 Object.keys(listCarrito).map((item,i)=>{

    //                     if(gruposDescuento.find(element => element.codDescuento == item_general.codDescuento && element.codProducto == item)){

    //                         aplicarDescuento[item]={
    //                             codigo : item,
    //                             cantidad : listCarrito[item].cantidad,
    //                             precio : listCarrito[item].precio,
    //                             codigoDescueto : item_general.codDescuento
    //                         }
    //                     }
    //                 })

    //                 let totalValor = 0
    //                 let totalCantidad = 0

    //                 if(item_general.tipoDescuento === 'B' && item_general.aplicadoEn === 'P'){

    //                     aplicarDescuento.map((producto, y)=>{
    //                         totalValor += producto.cantidad*producto.precio
    //                     })

    //                     if(totalValor >= item_general.valorMinimo && totalValor <= item_general.valorMaximo){

    //                         let descuento = (totalValor*item_general.porcentaje)/100
    //                         descuento_total += descuento
                            
    //                         aplicarDescuento.map((producto, y)=>{
                                
    //                             carrito[producto.codigo].codigoDescuento = item_general.codDescuento
    //                             carrito[producto.codigo].cantidadDescuento = producto.cantidad
    //                             carrito[producto.codigo].porcentajeDescuento = item_general.porcentaje
    //                             carrito[producto.codigo].descuentoAplicado = (producto.cantidad * producto.precio) * (item_general.porcentaje/100)
    //                             carrito[producto.codigo].clase = item_general.clase

    //                             delete listCarrito[producto.codigo]
    //                         })
    //                     } else {
    //                         aplicarDescuento.map((producto, y)=>{

    //                             delete    carrito[producto.codigo].codigoDescuento
    //                             delete    carrito[producto.codigo].cantidadDescuento
    //                             delete    carrito[producto.codigo].porcentajeDescuento
    //                             delete    carrito[producto.codigo].descuentoAplicado
    //                             delete    carrito[producto.codigo].clase
    //                         })
    //                     }
    //                 }
    //                 else if (item_general.tipoDescuento === 'B' && item_general.aplicadoEn === 'C') {

    //                     aplicarDescuento.map((producto, y)=>{
    //                         totalCantidad += producto.cantidad
    //                         totalValor += producto.cantidad*producto.precio
    //                     })

    //                     if(totalCantidad >= item_general.cantidadMinima) {

    //                         let descuento = (totalValor*item_general.porcentaje)/100
    //                         descuento_total += descuento
                            
    //                         aplicarDescuento.map((producto, y)=>{

    //                             carrito[producto.codigo].codigoDescuento = item_general.codDescuento
    //                             carrito[producto.codigo].cantidadDescuento = producto.cantidad
    //                             carrito[producto.codigo].porcentajeDescuento = item_general.porcentaje
    //                             carrito[producto.codigo].descuentoAplicado = (producto.cantidad * producto.precio) * (item_general.porcentaje/100)
    //                             carrito[producto.codigo].clase = item_general.clase

    //                             delete listCarrito[producto.codigo]
    //                         })
    //                     }
    //                 }
    //                 else if (item_general.tipoDescuento === 'V'){

    //                     aplicarDescuento.sort(compareValues('cantidad'));

    //                     let productosVolumen = []

    //                     aplicarDescuento.map((producto, y)=>{
    //                         totalCantidad += producto.cantidad
    //                     })

    //                     if(totalCantidad >= item_general.cantidadMinima){

    //                         totalCantidad = parseInt(totalCantidad - (totalCantidad % item_general.cantidadMinima))

    //                         aplicarDescuento.map((producto, y)=>{

    //                             let cantidadDescuento = 0
    //                             let cantidadSinDescuento = 0

    //                             if(producto.cantidad <= totalCantidad) {
    //                                 cantidadDescuento = producto.cantidad
    //                                 cantidadSinDescuento = producto.cantidad - cantidadDescuento
    //                                 totalCantidad -= cantidadDescuento
    //                             } else {
    //                                 cantidadSinDescuento = producto.cantidad - totalCantidad
    //                                 cantidadDescuento = producto.cantidad - cantidadSinDescuento
    //                                 totalCantidad = 0
    //                             }

    //                             productosVolumen[producto.codigo] = {
    //                                 codigo : producto.codigo,
    //                                 cantidad_Descuento : cantidadDescuento,
    //                                 precio : producto.precio
    //                             }
    //                         })

    //                         let descuento = 0

    //                         productosVolumen.map((productoVlmn, y)=>{

    //                             let descuentoProducto = (productoVlmn.cantidad_Descuento * productoVlmn.precio) * (item_general.porcentaje/100)

    //                             descuento += descuentoProducto

    //                             carrito[productoVlmn.codigo].codigoDescuento = item_general.codDescuento
    //                             carrito[productoVlmn.codigo].cantidadDescuento = productoVlmn.cantidad_Descuento
    //                             carrito[productoVlmn.codigo].porcentajeDescuento = item_general.porcentaje
    //                             carrito[productoVlmn.codigo].descuentoAplicado = descuentoProducto
    //                             carrito[productoVlmn.codigo].clase = item_general.clase

    //                             delete listCarrito[productoVlmn.codigo]
    //                         })

    //                         descuento_total += descuento

    //                     }
    //                 }
    //             }
    //         })

    //         localStorage.setItem(empresa.codigo_empresa, JSON.stringify(carrito));
    //         return descuento_total
    //     }
    // }
}

// const compareValues =  (key, order = 'desc') => {
//     return function innerSort(a, b) {
//       if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
//         return 0;
//       }
  
//       const varA = (typeof a[key] === 'string')
//         ? a[key].toUpperCase() : a[key];
//       const varB = (typeof b[key] === 'string')
//         ? b[key].toUpperCase() : b[key];
  
//       let comparison = 0;
//       if (varA > varB) {
//         comparison = 1;
//       } else if (varA < varB) {
//         comparison = -1;
//       }
//       return (
//         (order === 'desc') ? (comparison * -1) : comparison
//       );
//     };
//   }