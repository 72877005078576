import React, { useState, useEffect } from 'react';
import Navbar from '../navbar/Navbar';
import Tabbar from '../navbar/Tabbar';
import $ from 'jquery'
import * as Constants from '../../constants/global';


//descargar excel
import ReactExport from "react-export-excel";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Excel = () => {

    let empresa = JSON.parse(localStorage.getItem('empresa'));

    var columns=['Código','Cantidad']

    const uploadFile=(e)=>{

        // console.log(e.target.files[0])

    }


    const [totalNavbar, setTotalNavbar] = useState();
    const [cantidad, setCantidad] = useState();

    const getCantidad = () => {

        Constants.calcularDescuento()
        let carrito = JSON.parse(localStorage.getItem(empresa.codigo_empresa)) || {};

        setCantidad(Object.keys(carrito).length)

        let iva = 0;
        let total = 0;
        let descuentoTotal = 0;

        Object.keys(carrito).map((item, i) => {

            let subtotal = (carrito[item]["cantidad"] * carrito[item]["precio"]);
            let descuento = subtotal * (carrito[item]["porcentajeDescuento"] ? carrito[item]["porcentajeDescuento"] / 100 : 0 )
            iva += ( (subtotal - descuento) * (carrito[item]["iva"] ? carrito[item]["iva"] / 100 : 0) );

            descuentoTotal += descuento
        })

        Object.keys(carrito).map((item, i) => {
            total += carrito[item]["cantidad"] * carrito[item]["precio"];
        })

        setTotalNavbar(total - descuentoTotal + iva);
    }

    useEffect(() => {
        getCantidad();
    })

    return (
        <React.Fragment>
            <div className="navbar principal">
                <Navbar item={4} cantidad={cantidad} totalNavbar={totalNavbar} />
            </div>

            <div className="excel">
                <form >
                    <div className="align-button"> 
                        <div className="custom-file">
                            <label htmlFor="cargarExcel">Cargar excel</label>                                               
                            <input id="cargarExcel" onChange={(e) => uploadFile(e)} type="file" className="left" /> 
                        </div>
                        <ExcelFile element={<a id="descargarPLantilla" className="button download">Descargar plantilla</a>}>
                            <ExcelSheet data={columns} name="Leaves">
                                <ExcelColumn label="Código" />
                                <ExcelColumn label="Cantidad" />
                            </ExcelSheet>
                        </ExcelFile>
                        <div>
                            <a id="agregarCarrito" className="button right">Agregar al carrito</a>
                        </div>
                    </div> 
                </form>
            </div>

            <Tabbar item={4} cantidad={cantidad}/>
        </React.Fragment>
    )
}

export default Excel;