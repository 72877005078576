import React, { useState, useEffect } from 'react';

import Navbar from '../navbar/Navbar';
import Tabbar from '../navbar/Tabbar';
import axios from 'axios';
import Help from '../../assets/img/help.png';
import Cookies from './Cookies';


import * as Constants from '../../constants/global';

const Ayuda = () => {

    let empresa = JSON.parse(localStorage.getItem('empresa'));

    const [totalNavbar, setTotalNavbar] = useState();
    const [cantidad, setCantidad] = useState();

    const [nombreVendedor, setNombreVendedor] = useState();
    const [telefonoVendedor, setTelefonoVendedor] = useState();
    const [telefonoServicioCliente, setTelefonoServicioCliente] = useState();
    

    const loadInfoAyuda = async () => {

        setNombreVendedor(empresa.sucursal.NombreVendedor ? empresa.sucursal.NombreVendedor : "")
        setTelefonoVendedor(empresa.sucursal.TelefonoVendedor ? empresa.sucursal.TelefonoVendedor : "")
        setTelefonoServicioCliente(empresa.sucursal.TelefonoServicioAlCliente ? empresa.sucursal.TelefonoServicioAlCliente : "")

    }
    
    const getCantidad = () => {

        Constants.calcularDescuento()
        let carrito = JSON.parse(localStorage.getItem(empresa.codigo_empresa)) || {};

        setCantidad(Object.keys(carrito).length)

        let iva = 0;
        let total = 0;
        let descuentoTotal = 0;

        Object.keys(carrito).map((item, i) => {

            let subtotal = (carrito[item]["cantidad"] * carrito[item]["precio"]);
            let descuento = subtotal * (carrito[item]["porcentajeDescuento"] ? carrito[item]["porcentajeDescuento"] / 100 : 0 )
            iva += ( (subtotal - descuento) * (carrito[item]["iva"] ? carrito[item]["iva"] / 100 : 0) );

            descuentoTotal += descuento
        })

        Object.keys(carrito).map((item, i) => {
            total += carrito[item]["cantidad"] * carrito[item]["precio"];
        })

        setTotalNavbar(total - descuentoTotal + iva);
    }

    useEffect(() => {
        loadInfoAyuda();
        getCantidad();
    })

    return (
        <React.Fragment>
            <div className="navbar principal">
                <Navbar item={10} cantidad={cantidad} totalNavbar={totalNavbar} />
            </div>

            <div className="pedido-list sugerido">
                <div className="seccion-ayuda">
                    <div className="logo-ayuda">
                        <img src={Help} width="40" height="40"></img>
                    </div>
                    <div className="start">
                        <a href="http://64.239.7.197/harinerasap/syncemart/imgs/manualweb.pdf" style={{margin:"auto"}} target="_blank" >Manual de usuario</a>
                        <br/>
                    </div>
                </div>
            </div>

            <Cookies />

            <Tabbar item={10} cantidad={cantidad}/>
            
        </React.Fragment>
    )
}


export default Ayuda;