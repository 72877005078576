import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import close from '../../assets/icon/close_grey.svg';

import * as Constants from '../../constants/global';

const ObservacionesModal = (props) => {

    const [observacion, setObservacion] = useState();

    const handleCancelar = () => {
        props.enviarPedido("")
        document.querySelector(".modal-observaciones").classList.remove("show-modal");
    }

    const handleEnviar = () => {
        props.enviarPedido(observacion)
        document.querySelector(".modal-observaciones").classList.remove("show-modal");
    }

    const handleObservaciones = (value) => {
        setObservacion(value);
    }

    return (

        <div className="modal modal-observaciones">
            <div className="modal-content">
                <div className="title">
                    <p>Observaciónes</p>
                </div>
                <div className="content sm encuesta">
                    <input 
                        type="text"
                        onChange={e => handleObservaciones(e.target.value)}
                        value={observacion}>
                    </input>
                </div>
                <div className="footer">
                    <button type="submit" className="btn-large outline" onClick={() => handleCancelar()}>Cancelar</button>
                    <button type="submit" className="btn-large" onClick={() => handleEnviar()}>Enviar</button>
                </div>
            </div>
        </div>
    )
}

export default withRouter(ObservacionesModal);