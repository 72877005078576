import React, { useState, useEffect } from 'react';
import * as Constants from '../../constants/global';
import axios from 'axios';

const Cookies = () => {

    let empresa = JSON.parse(localStorage.getItem('empresa'));

    const [ipv4, setIpv4] = React.useState("");
    const [cookies, setCookies] = React.useState(0);

    const publicIp = require('public-ip');
 
    const getIpv4 = async () => {
        setIpv4(await publicIp.v4());        
    };

    const loadCookies = () => {
        setCookies(empresa.sucursal.cookies);      
    };

    const handleAceptarCookies = () => {

        let url = Constants.URL_BASE + Constants.WSACEPTARCOOKIES;

        let data = {
            "Codigo": empresa.sucursal.codigo,
            "DireccionIp": ipv4
        };

        axios.post(url, data)
        .then(res => {

            if(res.data.Activado === 1){

                setCookies(1);

                empresa.sucursal.cookies = 1;
                localStorage.setItem('empresa', JSON.stringify(empresa));

                alert(res.data.Mensaje);
            }
        });
    }

    useEffect(() => {
        loadCookies();
        getIpv4();
    }, [cookies])

    return (
        <React.Fragment>
            {cookies == 0 ? (
                <div className={"cookies"}>
                    <p>
                        Éste sitio web usa cookies, si permanece aquí acepta su uso. 
                        Puede leer más sobre el uso de cookies en nuestra <a href="" target="_blank">política de privacidad</a>.
                    </p>
                    <button onClick={() => handleAceptarCookies()}>Aceptar</button>
                </div>
            ) : null}
        </React.Fragment>
    )
}

export default Cookies;