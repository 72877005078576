import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import close from '../../assets/icon/close_grey.svg';

import * as Constants from '../../constants/global';

import axios from 'axios';

import {RadioGroup, Radio} from 'react-radio-group'

const EncuestaModal = (props) => {

    const [codEncuesta, setCodEncuesta] = useState("");

    const [numEncuesta, setNumEncuesta] = useState("");

    const [indice, setIndice] = useState();
    const [indiceFinal, setIndiceFinal] = useState();
    const [codPregunta, setCodPregunta] = useState();
    const [tipoPregunta, setTipoPregunta] = useState();
    const [pregunta, setPregunta] = useState();
    const [observacion, setObservacion] = useState();

    const [params, setParams] = useState([]);

    const [respuestaAbierta, setRespuestaAbierta] = useState("");
    const [codPreguntaUnica, setCodPreguntaUnica] = useState(-1);

    const [siguiente, setSiguiente] = useState(0);
    const [mensajeAlerta, setMensajeAlerta] = useState("");

    const[respuestas] = useState({"ListaRespuestas": []})

    // const handleClose = () => {
    //     document.querySelector(".modal-encuesta").classList.remove("show-modal");
    // }

    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    const handleIniciar = () => {

        setCodEncuesta(props.encuesta["encuesta"]["codEncuesta"])
        setNumEncuesta("AENC" + props.codProveedor.toString() + props.codCliente.toString().trim() + Constants.getDate())

        setIndice(0)
        setIndiceFinal(props.encuesta["preguntas"].length-1)

        setCodPregunta(props.encuesta["preguntas"][0]["codPregunta"])
        setTipoPregunta(props.encuesta["preguntas"][0]["codTipoPregunta"])
        setPregunta(props.encuesta["preguntas"][0]["descripcion"])
        setObservacion(props.encuesta["preguntas"][0]["observacion"])

        setParams(props.encuesta["param"].filter(o => o.codPregunta === props.encuesta["preguntas"][0]["codPregunta"]));

    }

    const handleSiguientePregunta = (index) => {

        if (validar()) {

            guardarRespuesta();

            if (index == indiceFinal){

                enviarEncuesta();
                document.querySelector(".modal-encuesta").classList.remove("show-modal");

            } else {
                index += 1

                setCodPregunta(props.encuesta["preguntas"][index]["codPregunta"])
                setTipoPregunta(props.encuesta["preguntas"][index]["codTipoPregunta"])
                setPregunta(props.encuesta["preguntas"][index]["descripcion"])
                setObservacion(props.encuesta["preguntas"][index]["observacion"])
        
                setParams(props.encuesta["param"].filter(a => a.codPregunta === props.encuesta["preguntas"][index]["codPregunta"]));
        
                setIndice(index)
            }
        }
    }

    const validar = () => {
        if(siguiente == 0){
            setMensajeAlerta((tipoPregunta != 1 ? "Debe seleccionar una opción": "Debe ingresar una respuesta"))
            return false;
        } else{
            setMensajeAlerta("")
            return true;
        }
    }

    const guardarRespuesta = () => {

        let descripcion = ""
        let obj = []

        let nit = localStorage.getItem("nit");

        if(tipoPregunta != "1"){
            obj = props.encuesta["param"].filter(o => o.codParamPreg === parseInt(codPreguntaUnica))
            descripcion = obj[0]["descripcion"]
        }
        
        respuestas["ListaRespuestas"].push(
            {
                "codEncuesta" : codEncuesta.toString(),
                "codPregunta": codPregunta.toString(),
                "codCliente": nit.trim(),
                "codVendedor": props.codProveedor,
                "numRespuesta": numEncuesta,
                "codParamPreg": codPreguntaUnica,
                "descripcionParamPreg": (tipoPregunta != 1 ? descripcion : "-1"),
                "respuesta": (tipoPregunta != 1 ? descripcion : respuestaAbierta),
                "observacion": observacion.toString()
            }
        );

        setRespuestaAbierta("");
        setCodPreguntaUnica(-1);
        setSiguiente(0);

    }

    const enviarEncuesta = () => {

        let url = Constants.URL_BASE + Constants.WSENCUESTA + "?codigo=" + props.codEmpresa;

        axios.post(url, respuestas)
            .then(res => {

                localStorage.setItem("encuestaEnviada", "1");

                alert("Encuesta enviada exitosamente");
                props.history.replace("/catalogo", "urlhistory");
                
            });

    }

    const handleOpcionRadioChange = (value) => {
        setCodPreguntaUnica(value);
        setSiguiente(1);
    }

    const handleRespuestaAbierta = (value) => {
        setRespuestaAbierta(value);
        setSiguiente(1);
    }

    const handleCancelar = () => {
        props.history.replace("/catalogo", "urlhistory");
    }

    return (

        <div className="modal modal-encuesta">
            <div className="modal-content">
                <div className="title">
                    <p>{ pregunta ? pregunta : props.encuesta["encuesta"]["descripcion"] }</p>
                </div>

                {codPregunta ? (
                    <React.Fragment>
                        <div className="content sm encuesta">
                            {tipoPregunta == 1 ? (
                                <React.Fragment>
                                    <input 
                                        type="text"
                                        placeholder="Respuesta"
                                        onChange={e => handleRespuestaAbierta(e.target.value)}
                                        value={respuestaAbierta}>
                                    </input>
                                </React.Fragment>
                            ) : tipoPregunta == 2 ? (
                                <React.Fragment>
                                    {params.map((item, i) => (
                                        <div>
                                            <input 
                                                type="checkbox" 
                                                name="valorCheck" 
                                                id={item.codParamPreg} 
                                                value={item.codParamPreg} />  

                                            <label for={item.descripcion}>{item.descripcion}</label>   
                                        </div>
                                    ))}
                                </React.Fragment>
                            ) : tipoPregunta == 3 ? (
                                <React.Fragment>
                                    { params.map((item, i) => (

                                        <RadioGroup name="opcion" selectedValue={codPreguntaUnica} onChange={handleOpcionRadioChange.bind(this)}>
                                            <Radio value={item.codParamPreg} />{item.descripcion}
                                        </RadioGroup>
                                    ))}
                                </React.Fragment>
                            ) : null }

                            { mensajeAlerta != "" ? (
                                <div className="contenido">
                                    <hr/>
                                    <p style={{color:"red"}}>
                                        {mensajeAlerta}
                                    </p>
                                </div>
                            ) : null }

                        </div>
                    </React.Fragment>
                ): null }

                {/* 1 abierta
                2 seleccion multiple 
                3 seleccion unica  */}
                
                <div className="footer">
                    <button type="submit" className="btn-large outline" onClick={() => handleCancelar()}>Cancelar</button>
                    {codPregunta ? (
                        <React.Fragment>
                            <button type="submit" className="btn-large" onClick={() => handleSiguientePregunta(indice)}>{indice != indiceFinal ? "Siguiente" : "Finalizar"}</button>
                        </React.Fragment>
                    ): (
                        <React.Fragment>
                            <button type="submit" className="btn-large" onClick={() => handleIniciar()}>Iniciar encuesta</button>
                        </React.Fragment>
                    ) }
                </div>
            </div>
        </div>
    )
}

export default withRouter(EncuestaModal);