import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import * as Constants from '../../constants/global';

import SucursalModal from '../modal/SucursalModal';
import axios from 'axios';

import noimage from '../../assets/img/noimage.png';

const Home = (props) => {
    const [empresas, setEmpresas] = useState();
    const [carrito, setCarrito] = useState({});
    const [empresa, setEmpresa] = useState({sucursales: []});

    useEffect(() => {
        const empresasData = async () => {
            let dataObject = JSON.parse(localStorage.getItem('empresas'));
            setEmpresas(dataObject.empresas);

            if (dataObject.empresas){
                if (dataObject.empresas.length == 1){
                    localStorage.setItem(dataObject.empresas[0].codigo_empresa, JSON.stringify(carrito));
                    handleEmpresa(dataObject.empresas[0])
                    
                }
            }
        };
        empresasData();

    }, [])

    const handleEmpresa = (item) => {
        if(item.sucursales.length > 1){
            localStorage.setItem('empresa', JSON.stringify(item));
            setEmpresa(item);
            document.querySelector(".modal-sucursal").classList.toggle("show-modal");
        }else{
            item["sucursal"] = item.sucursales[0];
            localStorage.setItem('empresa', JSON.stringify(item));

            // let url = (item["url"] ? (item["url"] + (item["path"] ? item["path"] + "/" : "")) :
            //         Constants.URL_BASE) +
            //         Constants.WSSUGERIDO +
            //         "?origen=" + item["codigo_empresa"] + 
            //         "&cliente=" + item["sucursal"]["codigo"];
            // axios.get(url)
            // .then(res => {
            //     if(res.data.length > 0){
                    // props.history.push('/sugerido');
                // } else {
                    props.history.push('/catalogo');
                // }
            // });

        } 
    }

    return (
        <React.Fragment>
            <div className="navbar">
                <div className="nav-header">
                    <div className="nav-content">
                        {/* <div className="nav-logo">
                            <img src={logo} alt="logo app"></img>
                        </div> */}
                        <h4>Empresas</h4>
                    </div>
                </div>
            </div>
            {/* <div className="content">
                {empresas ? (
                    <div className="empresas">
                        {empresas.map((item, i) => (
                            <div className="card" key={i} onClick={() => handleEmpresa(item)}>
                                <img 
                                    src={'http://66.33.94.211/logo/'+item.codigo_empresa+'/'+item.codigo_empresa+'.png'}
                                    onError={(e)=>{e.target.onerror = null; e.target.src=noimage}}>
                                </img>
                            </div>
                        ))}
                    </div>
                ) : null}
            </div> */}
            <SucursalModal empresa={empresa} />
        </React.Fragment>
    )
}


export default withRouter(Home);