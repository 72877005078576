import React, { useState, useEffect } from 'react';

import Navbar from '../navbar/Navbar';
import Tabbar from '../navbar/Tabbar';
import axios from 'axios';

import DetallePedidoModal from '../modal/DetallePedidoModal';

import * as Constants from '../../constants/global';

const Historial = () => {
    console.log("entra");
    let empresa = JSON.parse(localStorage.getItem('empresa'));

    const [totalNavbar, setTotalNavbar] = useState();
    const [cantidad, setCantidad] = useState();
    const [historial, setHistorial] = useState();
    
    const [detalle, setDetalle] = useState();

    const loadHistorial = async () => {
        let url = Constants.URL_BASE +
                    Constants.WSHISTORIAL +
                    "?codigo=" + empresa.codigo_empresa + 
                    "&cliente=" + empresa.sucursal.codigo;

        axios.get(url)
        .then(res => {
            console.log("historial",res);
            setHistorial(res.data);

        });
    }
    
    const getCantidad = () => {

        Constants.calcularDescuento()
        let carrito = JSON.parse(localStorage.getItem(empresa.codigo_empresa)) || {};

        setCantidad(Object.keys(carrito).length)

        let iva = 0;
        let total = 0;
        let descuentoTotal = 0;

        Object.keys(carrito).map((item, i) => {

            let subtotal = (carrito[item]["cantidad"] * carrito[item]["precio"]);
            let descuento = subtotal * (carrito[item]["porcentajeDescuento"] ? carrito[item]["porcentajeDescuento"] / 100 : 0 )
            iva += ( (subtotal - descuento) * (carrito[item]["iva"] ? carrito[item]["iva"] / 100 : 0) );

            descuentoTotal += descuento
        })

        Object.keys(carrito).map((item, i) => {
            total += carrito[item]["cantidad"] * carrito[item]["precio"];
        })

        setTotalNavbar(total - descuentoTotal + iva);
    }

    const handleDetallePedido = (item) => {
        setDetalle(item)
        document.querySelector(".modal-sucursal").classList.toggle("show-modal");
    }

    useEffect(() => {
        console.log("entra al historial");
        getCantidad();
        loadHistorial();
    },[])

    return (
        <React.Fragment>
            <div className="navbar principal">
                <Navbar item={6} cantidad={cantidad} totalNavbar={totalNavbar} />
            </div>

            <div className="pedido-list sugerido">
                {historial ? (
                    <div className="product-list">
                        {Object.keys(historial).map((item, i) => (
                            <div className="item item-carrito" key={i} style={{cursor:"pointer"}} onClick={() => handleDetallePedido(historial[item].detalle)}>
                                <div className="start">
                                    <p className="nombre">Numero Documento: {historial[item].numero_doc}</p>
                                    <p className="nombre">Fecha: {historial[item].fecha_movil}</p>
                                    <p className="precio">Total: {Constants.copFormat(Object.values(historial[item].detalle).reduce((r, { precio, cantidad, iva }) => r + (precio * cantidad) * (1 + ( (iva ? iva : 0) / 100)) , 0))}
                                    {/* {   
                                        new Intl.NumberFormat("es-CO", {
                                        style: "currency",
                                        currency: "COP"
                                        }).format(Object.values(historial[item].detalle).reduce((r, { precio, cantidad, iva }) => r + (precio * cantidad) * (1 + ( (iva ? iva : 0) / 100)) , 0))
                                    } */}
                                    </p>
                                </div>
                                <div className="end">
                                </div>
                            </div>
                        ))}
                    </div>
                ) : null}
            </div>

            <Tabbar item={6} cantidad={cantidad}/>

            <DetallePedidoModal detalle={detalle}/>
            
        </React.Fragment>
    )
}


export default Historial;