import React, { useState, useEffect } from 'react';

import Navbar from '../navbar/Navbar';
import Tabbar from '../navbar/Tabbar';
import axios from 'axios';
import Cookies from './Cookies';


import DetallePedidoModal from '../modal/DetallePedidoModal';

import * as Constants from '../../constants/global';

const UltimosPedidos = () => {

    let empresa = JSON.parse(localStorage.getItem('empresa'));

    const [totalNavbar, setTotalNavbar] = useState();
    const [cantidad, setCantidad] = useState();
    const [ultimosPedidos, setUltimosPedidos] = useState();
    
    const [detalle, setDetalle] = useState();

    const loadUltimosPedidos = async () => {
        let url = Constants.URL_BASE +
                    Constants.WSPEDIDO +
                    "?origen=" + empresa.codigo_empresa + 
                    // "&cliente=" + "0000157572";
                    "&cliente=" + empresa.sucursal.codigo;

        axios.get(url)
        .then(res => {
            console.log(res.data);
            setUltimosPedidos(res.data);
        });
    }
    
    const getCantidad = () => {

        Constants.calcularDescuento()
        let carrito = JSON.parse(localStorage.getItem(empresa.codigo_empresa)) || {};

        setCantidad(Object.keys(carrito).length)

        let iva = 0;
        let total = 0;
        let descuentoTotal = 0;

        Object.keys(carrito).map((item, i) => {

            let subtotal = (carrito[item]["cantidad"] * carrito[item]["precio"]);
            let descuento = subtotal * (carrito[item]["porcentajeDescuento"] ? carrito[item]["porcentajeDescuento"] / 100 : 0 )
            iva += ( (subtotal - descuento) * (carrito[item]["iva"] ? carrito[item]["iva"] / 100 : 0) );

            descuentoTotal += descuento
        })

        Object.keys(carrito).map((item, i) => {
            total += carrito[item]["cantidad"] * carrito[item]["precio"];
        })

        setTotalNavbar(total - descuentoTotal + iva);
    }

    const handleDetallePedido = (item) => {
        setDetalle(item)
        document.querySelector(".modal-sucursal").classList.toggle("show-modal");
    }

    useEffect(() => {
        getCantidad();
        loadUltimosPedidos();
    },[])

    const retornarFecha = (fecha) => {

        var dateParts = fecha.split("-");
        var date = new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2]); 

        let dia = '' + date.getDate()
        let mes = '' + (date.getMonth() + 1)
        let anio = date.getFullYear()

        dia = (dia.length < 2 ? '0' + dia : dia)
        mes = (mes.length < 2 ? '0' + mes : mes)

        let fechaCompleta = dia + '/' + mes + '/' + anio

        return fechaCompleta
    }

    return (
        <React.Fragment>
            <div className="navbar principal">
                <Navbar item={7} cantidad={cantidad} totalNavbar={totalNavbar} />
            </div>

            <div className="pedido-list sugerido">
                {ultimosPedidos ? (
                    <div className="product-list">
                        {Object.keys(ultimosPedidos).map((item, i) => (
                            <div className="item item-carrito" key={i} style={{cursor:"pointer"}} onClick={() => handleDetallePedido(ultimosPedidos[item].detalle)}>
                                <div className="start">
                                    <p className="nombre"><b>TIPO PEDIDO:</b> {(ultimosPedidos[item].numero_doc.substring(0, 1) == "E" ? "Cliente" : "Vendedor" )}</p>
                                    <p className="nombre"><b>NÚMERO DOCUMENTO:</b> {ultimosPedidos[item].numero_doc}</p>
                                    <p className="nombre"><b>FECHA:</b> { retornarFecha(ultimosPedidos[item].fecha.replace("T", " "))}</p>
                                    {/* <p className="precio">Total: {
                                            new Intl.NumberFormat("es-CO", {
                                            style: "currency",
                                            currency: "COP"
                                            }).format(Object.values(ultimosPedidos[item].detalle).reduce((r, { precio, cantidad, iva }) => r + (precio * cantidad) * (1 + ( (iva ? iva : 0) / 100)) , 0))
                                        }
                                    </p> */}
                                    <p className="nombre"><b>NÚMERO PEDIDO:</b> {ultimosPedidos[item].numeroPedido}</p>
                                    <p className="nombre"><b>DIRECCIÓN DE ENTREGA:</b> {ultimosPedidos[item].direccionEntrega}</p>
                                    {/* <p className="precio">Valor Pedido: {
                                            new Intl.NumberFormat("es-CO", {
                                            style: "currency",
                                            currency: "COP"
                                            }).format(ultimosPedidos[item].valorPedido)
                                        }
                                    </p> */}
                                    <p className="nombre"><b>NÚMERO DE FACTURA:</b> {ultimosPedidos[item].factura}</p>
                                    <p className="precio"><b>VALOR FACTURADO:</b> {Constants.copFormat(ultimosPedidos[item].valorFacturado)}
                                        {/* {
                                            new Intl.NumberFormat("es-CO", {
                                            style: "currency",
                                            currency: "COP"
                                            }).format(ultimosPedidos[item].valorFacturado)
                                        } */}
                                    </p>
                                </div>
                                <div className="end">
                                </div>
                            </div>
                        ))}
                    </div>
                ) : null}
            </div>

            <Cookies />

            <Tabbar item={7} cantidad={cantidad}/>

            <DetallePedidoModal detalle={detalle}/>
            
        </React.Fragment>
    )
}


export default UltimosPedidos;