import React, { useState, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';
import { withRouter } from "react-router-dom";
import Swal from 'sweetalert2'
import Navbar from '../navbar/Navbar';
import Tabbar from '../navbar/Tabbar';
import DatePicker, { registerLocale } from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es'
import App from "../../../src/App.css"

import * as Constants from '../../constants/global';

import noimage from '../../assets/img/noimage.png';

import { ReactComponent as Minus } from '../../assets/icon/minus.svg';
import { ReactComponent as Plus } from '../../assets/icon/plus.svg';
import Trash from '../../assets/img/trash.png';
import error from '../../assets/icon/error.svg';

import EncuestaModal from '../modal/EncuestaModal';
import ObservacionesModal from '../modal/ObservacionesModal';

registerLocale("es", es)

const Carrito = (props) => {

    const [carrito, setCarrito] = useState();
    const [total, setTotal] = useState(0);
    const [iva, setIva] = useState(0);
    const [codigoNum, setCodigoNum] = useState(0)
    const [maximoPedido, setMaximoPedido] = useState(false);
    // const [loader, setLoader] = React.useState(false);
    const [descuento_total, setDescuento_total] = useState(0);
    const [recogidaPlanta, setRecogidaPlanta] = useState(false);
    const [totalNavbar, setTotalNavbar] = useState();
    const [cantidad, setCantidad] = useState();
    const [encuesta, setEncuesta] = React.useState({ encuesta: { descripcion: "" } });
    const [minCalendar, setminCalendar] = useState();
    const [maxCalendar, setmaxCalendar] = useState();
    const [valueCalendar, onChangeCalendar] = useState(new Date());
    const [canalCliente, setCanalCliente] = useState("")
    const [ordenCompra, setOrdenCompra] = useState();
    const [detalleOrdenCompra, setDetalleOrdenCompra] = useState();
    // const [listasDescuento, setListasDescuento] = useState();
    // const [gruposDescuento, setGruposDescuento] = useState();
    let empresa = JSON.parse(localStorage.getItem('empresa'));

    let encuestaEnviada = localStorage.getItem("encuestaEnviada");

    // const cargarListasDescuento = (urlEmpresa, pathEmpresa, codigoEmpresa, sucursalEmpresa) => {
    //     let url = (urlEmpresa ? (urlEmpresa + (pathEmpresa ? pathEmpresa + "/" : "")) :
    //         Constants.URL_BASE) +
    //         Constants.WSLISTASDESCUENTO +
    //         "?codigo=" + codigoEmpresa + 
    //         "&cliente=" + sucursalEmpresa;

    //     axios.get(url)
    //     .then(res => {
    //         // setLoader(false);
    //         // setListasDescuento(res.data);
    //         localStorage.setItem("listasDescuento", JSON.stringify(res.data));
    //     });
    // }

    // const cargarGruposDescuento = async (urlEmpresa, pathEmpresa, codigoEmpresa, sucursalEmpresa) => {

    //     let url = (urlEmpresa ? (urlEmpresa + (pathEmpresa ? pathEmpresa + "/" : "")) :
    //         Constants.URL_BASE) +
    //         Constants.WSGRUPOSDESCUENTO +
    //         "?codigo=" + codigoEmpresa + 
    //         "&cliente=" + sucursalEmpresa;

    //     axios.get(url)
    //     .then(res => {
    //         // setGruposDescuento(res.data);
    //         localStorage.setItem("gruposDescuento", JSON.stringify(res.data));
    //     });
    // }

    const getCantidad = () => {

        let carrito = JSON.parse(localStorage.getItem(empresa.codigo_empresa)) || {};

        setCantidad(Object.keys(carrito).length)

        let iva = 0;
        let total = 0;
        let descuentoTotal = 0;

        Object.keys(carrito).map((item, i) => {

            let subtotal = (carrito[item]["cantidad"] * carrito[item]["precio"]);
            let descuento = subtotal * (carrito[item]["porcentajeDescuento"] ? carrito[item]["porcentajeDescuento"] / 100 : 0)
            iva += ((subtotal - descuento) * (carrito[item]["iva"] ? carrito[item]["iva"] / 100 : 0));

            descuentoTotal += descuento
        })

        Object.keys(carrito).map((item, i) => {
            total += carrito[item]["cantidad"] * carrito[item]["precio"];
        })

        setTotalNavbar(total - descuentoTotal + iva);
    }

    const loadHistorial = async () => {
        let url = Constants.URL_BASE +
            Constants.WSHISTORIAL +
            "?codigo=" + empresa.codigo_empresa +
            "&cliente=" + empresa.sucursal.codigo;

        axios.get(url)
            .then(res => {
                if (res.data.length >= empresa.max_pedidos) {
                    setMaximoPedido(true);
                } else {
                    setMaximoPedido(false);
                }
            });
    }

    const getIva = () => {

        let descuento = Constants.calcularDescuento()

        setDescuento_total(descuento ? descuento : 0)

        let carrito = JSON.parse(localStorage.getItem(empresa.codigo_empresa)) || {};

        let iva = 0;
        Object.keys(carrito).map((item, i) => {
            let subtotal = (carrito[item]["cantidad"] * carrito[item]["precio"]);
            let descuento = subtotal * (carrito[item]["porcentajeDescuento"] ? carrito[item]["porcentajeDescuento"] / 100 : 0)
            iva += ((subtotal - descuento) * (carrito[item]["iva"] ? carrito[item]["iva"] / 100 : 0));
        })
        setIva(iva);
    }

    const getTotal = () => {


        let carrito = JSON.parse(localStorage.getItem(empresa.codigo_empresa)) || {};

        let total = 0;
        Object.keys(carrito).map((item, i) => {
            total += carrito[item]["cantidad"] * carrito[item]["precio"];
        })

        setTotal(total);

        /***--------- Fin Calculo Subtotal ----------****/

    }

    // AUMENTA LA CANTTIDAD
    const handleMas = (codigo, item) => {
        if (carrito[codigo]) {
            carrito[codigo]["cantidad"] += 1;
        } else {
            carrito[codigo] = {
                nombre: item.nombre,
                precio: item.precio,
                cantidad: 1
            }
        }
        localStorage.setItem(empresa.codigo_empresa, JSON.stringify(carrito));
        setCarrito(carrito);
        getIva();
        getTotal();
        getCantidad();
    }

    const handleEliminar = (codigo, item) => {

        if (carrito[codigo]) {
            delete carrito[codigo];
        }

        localStorage.setItem(empresa.codigo_empresa, JSON.stringify(carrito));
        setCarrito(carrito);
        getIva();
        getTotal();
        getCantidad();
    }

    const handleMenos = (codigo, item) => {
        if (carrito[codigo]) {
            if (carrito[codigo]["cantidad"] == 1) {
                delete carrito[codigo];
            } else {
                carrito[codigo]["cantidad"] -= 1;
            }
        }
        localStorage.setItem(empresa.codigo_empresa, JSON.stringify(carrito));
        setCarrito(carrito);
        getIva();
        getTotal();
        getCantidad();
    }

    const handleCancelarPedido = () => {
        Object.keys(carrito).map((item, i) => {
            delete carrito[item];
        });

        localStorage.setItem(empresa.codigo_empresa, JSON.stringify(carrito));
        props.history.replace("/catalogo", "urlhistory");
    }

    const enviarPedido = (observacion) => {
        let fechaEntrega = moment(valueCalendar).format('YYYY-MM-DD 00:00:00')
        let fechaMin = moment(minCalendar).format('YYYY-MM-DD 00:00:00')
        var codigoFecha = moment().format('YYYYMMDDHHmmssSSS');
        var sapVendedor = empresa.sucursal.vendedor
        var numeroDoc = sapVendedor + codigoFecha + codigoNum
        let fechaActual = new Date();
        let hora = fechaActual.getHours();
        let minutos = fechaActual.getMinutes();
        let validador = false;

        empresa.hora_limite = (empresa.hora_limite ? empresa.hora_limite : "")

        if (empresa.hora_limite == "") {
            validador = true
        } else if (parseInt(hora + '' + minutos) <= parseInt(empresa.hora_limite.replace(":", ""))) {
            validador = true
        } else {
            alert("Su pedido no puede ser registrado en este momento.\nRecuerde que la hora máxima para enviar sus pedidos es: " + empresa.hora_limite + "")
        }

        if (fechaEntrega < fechaMin && recogidaPlanta == false) {
            Swal.fire(
                'Datos incompletos',
                'Para finalizar el pedido debes elegir una fecha de entrega o puedes marcar la opción de recoger en planta ',
                'warning'
            )
        } else {
            if (validador) {
                let data = [];
                let count = 0;
                Object.keys(carrito).map((item, i) => {
                    count++;
                    data.push({
                        "NumeroDoc": codigoFecha,
                        "CodigoCliente": empresa.sucursal.codigo,
                        "CodigoProveedor": empresa.num_empresa,
                        "FechaMovil": moment().format('YYYY-MM-DD HH:mm'),
                        "Precio": parseInt(carrito[item]["precio"]),
                        "Iva": parseInt(carrito[item]["iva"]),
                        "Posicion": count,
                        "Cantidad": parseInt(carrito[item]["cantidad"]),
                        "CodigoProducto": item,
                        "ValorDescuento": carrito[item]["descuentoAplicado"],
                        "Param1": carrito[item]["porcentajeDescuento"],
                        "Param2": parseInt(carrito[item]["cantidadDescuento"]),
                        "Param3": parseInt(carrito[item]["codigoDescuento"]),
                        "Param4": detalleOrdenCompra,
                        "Param5": empresa.sucursal.canal,
                        "DescripcionParam1": carrito[item]["porcentajeDescuento"] ? "Porcentaje Descuento" : "",
                        "DescripcionParam2": carrito[item]["cantidadDescuento"] ? "Cantidad Descuento Aplicada" : "",
                        "DescripcionParam3": recogidaPlanta == true ? "NULL" : fechaEntrega,
                        "DescripcionParam4": recogidaPlanta == true ? 1 : 0,
                        "DescripcionParam5": "Canal",
                        "Observacion": observacion
                    })
                });

                if (data.length > 0) {
                    let url = Constants.URL_BASE + Constants.WSPEDIDO + "?codigo=" + empresa.codigo_empresa;
                    axios.post(url, { "ListaDetalle": data })
                        .then(res => {
                            Swal.fire(
                                'Numero de solicitud : ' + res.data.Orden,
                                'Pedido almacenado correctamente.',
                                'success'
                            )
                            Object.keys(carrito).map((item, i) => {
                                delete carrito[item];
                            });
                            localStorage.setItem(empresa.codigo_empresa, JSON.stringify(carrito));
                            numeroDoc = ""
                            if ((empresa.sucursal.primerPedido == 0 && encuestaEnviada == "0")) {
                                aplicarEncuesta();
                            } else {
                                props.history.replace("/catalogo", "urlhistory");
                            }

                        });
                }
            }
        }

    }

    const handleObservaciones = () => {
        document.querySelector(".modal-observaciones").classList.toggle("show-modal");
    }

    const handlePedido = () => {
        handleObservaciones();
    }

    const aplicarEncuesta = () => {
        let url = Constants.URL_BASE + Constants.WSENCUESTA;

        axios.get(url)
            .then(res => {
                setEncuesta(res.data[0]);
                document.querySelector(".modal-encuesta").classList.toggle("show-modal");
            });
    }

    const handleChangeCantidad = (codigo, item, valor) => {
        if (valor <= 0) {
            delete carrito[codigo];
        } else {
            if (carrito[codigo]) {
                carrito[codigo]["cantidad"] = parseInt(valor);
            } else {
                carrito[codigo] = {
                    nombre: item.nombre,
                    precio: item.precio,
                    cantidad: parseInt(valor)
                }
            }
        }
        localStorage.setItem(empresa.codigo_empresa, JSON.stringify(carrito));
        setCarrito(carrito);
        getIva();
        getTotal();
        getCantidad();
    }

    const getLastNumeroDoc = () => {
        console.log("entra");
        axios.get(Constants.URL_BASE + Constants.LASTNUMERODOC)
            .then(res => {
                setCodigoNum(res.data[0].lastNumeroDoc)
            }).catch(err => {
                console.log(err);
            })
    }
    useEffect(() => {
        let canalCliente = JSON.parse(localStorage.getItem("empresa"))
        setCanalCliente(canalCliente.sucursal.canal)
        setCarrito(JSON.parse(localStorage.getItem(empresa.codigo_empresa)) || {});
        getIva();
        getTotal();
        getCantidad();
        loadHistorial();
        let obtenerFecha = new Date()
        let diaActual = obtenerFecha.getDay()
        if (diaActual == 6) {
            let fechaMin = new Date()
            fechaMin.setDate(fechaMin.getDate() + 5)
            setminCalendar(fechaMin)
            if (canalCliente.sucursal.canal == "TYP") {
                let fechaMax = new Date()
                fechaMax.setDate(fechaMax.getDate() + 12)
                setmaxCalendar(fechaMax)
            } else {
                let fechaMax = new Date()
                fechaMax.setDate(fechaMax.getDate() + 20)
                setmaxCalendar(fechaMax)
            }
        } else if (diaActual == 0) {
            let fechaMin = new Date()
            fechaMin.setDate(fechaMin.getDate() + 4)
            setminCalendar(fechaMin)
            if (canalCliente.sucursal.canal == "TYP") {
                let fechaMax = new Date()
                fechaMax.setDate(fechaMax.getDate() + 12)
                setmaxCalendar(fechaMax)
            } else {
                let fechaMax = new Date()
                fechaMax.setDate(fechaMax.getDate() + 19)
                setmaxCalendar(fechaMax)
            }
        } else {
            let fechaMin = new Date()
            fechaMin.setDate(fechaMin.getDate() + 2)
            setminCalendar(fechaMin)
            if (canalCliente.sucursal.canal == "TYP") {
                let fechaMax = new Date()
                fechaMax.setDate(fechaMax.getDate() + 8)
                setmaxCalendar(fechaMax)
            } else {
                let fechaMax = new Date()
                fechaMax.setDate(fechaMax.getDate() + 15)
                setmaxCalendar(fechaMax)
            }
        }
        console.log(props);
    }, [])

    return (
        <React.Fragment>
            <div className="navbar principal">
                <Navbar item={5} cantidad={cantidad} totalNavbar={totalNavbar} />
            </div>

            {/* {
    listasDescuento ? (
        <div>
            {
                listasDescuento.map((item_general, x)=>(
                        <p>{item_general.codDescuento}</p>
                    )
                )
            }
        </div>
    ) : null
} */}

            <div className="pedido-list carrito">
                {carrito ? (
                    <div className="product-list">
                        {Object.keys(carrito).map((item, i) => (
                            <div className="item item-carrito" key={i}>
                                <img width="120"
                                    className="img-product"
                                    src={'https://harinera-nes.celuwebcloud.com/SyncEmart/Imgs/' + empresa.num_empresa + '/' + item.toString().trim() + '.jpg'}

                                    // src={'https://fuerzaventas3.celuwebdev.com/HARINERASAP/SyncEmart/Imgs/' + empresa.num_empresa + '/' + item.toString().trim() + '.jpg'}
                                    onError={(e) => { e.target.onerror = null; e.target.src = noimage }} />
                                <div className="start">

                                    <p className="codigo"><b>Codigo: </b>{item}</p>
                                    <p className="nombre"><b>{carrito[item].nombre}</b></p>
                                    <p className="precio">
                                        {Constants.copFormat(carrito[item].precio)}
                                        {/* {new Intl.NumberFormat("es-CO", {
                                            style: "currency",
                                            currency: "COP"
                                            }).format(carrito[item].precio)} */}
                                    </p>
                                    <p className="regular">
                                        Precio de Referencia
                                        {" " + Constants.copFormat(carrito[item].precio) + " x " + carrito[item].cantidad}
                                        {/* {" " + new Intl.NumberFormat("es-CO", {
                                         style: "currency",
                                         currency: "COP"
                                         }).format(carrito[item].precio) + " x " + carrito[item].cantidad}  */}
                                    </p>
                                </div>
                                <div className="end">
                                    <div className="action">
                                        <div className="controls">
                                            <button role="button" onClick={() => handleMenos(item, carrito[item])}>
                                                {/* <img src={minus} alt="icon minus" width="15px" height="15px"></img> */}
                                                <Minus className="svg-una-capa" style={{ width: "15px", height: "15px" }} />
                                            </button>
                                            <input type="number"
                                                onChange={e => handleChangeCantidad(item, carrito[item], e.target.value)}
                                                value={carrito[item].cantidad} />
                                            <button role="button" onClick={() => handleMas(item, carrito[item])}>
                                                {/* <img src={plus} alt="icon plus" width="15px" height="15px"></img> */}
                                                <Plus className="svg-una-capa" style={{ width: "15px", height: "15px" }} />
                                            </button>
                                        </div>
                                        <a style={{ cursor: "pointer" }} onClick={() => handleEliminar(item, carrito[item])}>
                                            <img src={Trash} width="20px" />
                                            {/* <Trash className="svg-dos-capas" style={{width:"20px"}}/> */}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : null}
            </div>

            <div className="total">
                <div className="total-content">
                    <div className="title">
                        <p>PRELIQUIDACIÓN</p>
                    </div>
                    <hr />
                    <div className="item">
                        <p className="opc">Subtotal:</p>
                        <p className="val">
                            {Constants.copFormat(total)}
                            {/* {new Intl.NumberFormat("es-CO", {
                                style: "currency",
                                currency: "COP"
                            }).format(total)} */}
                        </p>
                    </div>

                    {/* { empresa.num_empresa == Constants.num_empresa_ramo ? (
                        <div className="item">
                            <p className="opc">Descuento:</p>
                            <p className="val">
                                {new Intl.NumberFormat("es-CO", {
                                    style: "currency",
                                    currency: "COP"
                                }).format(descuento_total)}
                            </p>
                        </div>
                    ) : null } */}

                    <div className="item">
                        <p className="opc">Iva:</p>
                        <p className="val">
                            {Constants.copFormat(iva)}
                            {/* {new Intl.NumberFormat("es-CO", {
                                style: "currency",
                                currency: "COP"
                            }).format(iva)} */}
                        </p>
                    </div>
                    <div className="item">
                        <p className="opc">Total:</p>
                        <p className="val">
                            {Constants.copFormat((total - descuento_total + iva))}
                            {/* {new Intl.NumberFormat("es-CO", {
                                style: "currency",
                                currency: "COP"
                            }).format((total - descuento_total + iva))} */}
                        </p>
                    </div>
                </div>
                <div className="miFuente" style={{ display: "flex" }}>
                    <label style={{ fontWeight: "bold", marginRight:"5px"}}><p>Tiene orden de compra ? </p> </label>
                    <input onChange={e => {
                        setOrdenCompra(e.target.checked)
                    }} type="checkbox" style={{ zoom: 1.0 }} defaultChecked={false} />
                </div>
                <div>
                    {ordenCompra == true ? (
                        <React.Fragment>
                            <div className="observacion">
                                <label style={{ fontWeight: "bold" }}>Orden de compra: </label>
                                <br></br>
                                <div  style={{ width: "42%", borderRadius: "4px", border: "solid 2px #0085B0" }}>
                                <input class="input" onChange={e => setDetalleOrdenCompra(e.target.value)} placeholder="Orden de compra:" maxLength="20" />
                                </div>
                            </div>
                        </React.Fragment>
                    ) : null}

                </div>
                {recogidaPlanta == false ? (
                    <div>
                        <div style={{ marginTop: "3%", marginBottom: "3%" }}>
                            <label style={{ fontWeight: "bold" }}>Elije la fecha de entrega </label>
                        </div>
                        <div style={{ maxWidth: "42%", borderRadius: "4px", border: "solid 2px #0085B0" }}>
                            <DatePicker
                                locale="es"
                                onChange={date => onChangeCalendar(date)}
                                selected={valueCalendar}
                                dateFormat="dd/MM/yyyy"
                                // calendarType="US"
                                minDate={minCalendar}
                                maxDate={maxCalendar}
                                filterDate={canalCliente == "REGULAR" ? date => date.getDay() != 6 && date.getDay() != 0 : date => date.getDay() != 0}
                            /></div>
                    </div>
                ) : null}
                {/* <div style={{ display: "flex", marginTop: "3%" }}>
                    <div>
                        <label style={{ fontWeight: "bold" }}>Desea recoger en planta ?</label>
                    </div>
                    <div>
                        <input
                            onChange={e => { setRecogidaPlanta(e.target.checked) }}
                            type="checkbox"
                            style={{ zoom: 1.5, marginLeft: "5px" }}
                            defaultChecked={false} />
                    </div>
                </div> */}
                <button type="submit" className="btn-large outline" onClick={() => handleCancelarPedido()}>Cancelar pedido</button>

                {maximoPedido ? (
                    <div className="carrito-validacion">
                        <div>
                            <img src={error} alt="icon plus" width="20px" height="20px"></img>
                        </div>
                        <div className="contenido">
                            <p><b>Cantidad maxima de pedidos superada</b></p>
                            <p>Solo se permiten maximo ({empresa.max_pedidos}) pedidos por día.</p>
                        </div>
                    </div>
                ) : (
                    <React.Fragment>
                        {empresa.pedido_minimo > (total + iva) ? (
                            <div className="carrito-validacion">
                                <div>
                                    <img src={error} alt="icon plus" width="20px" height="20px"></img>
                                </div>
                                <div className="contenido">
                                    <p><b>El valor de el pedido es muy poco</b></p>
                                    <p>Los pedidos deben ser mayor a
                                        {Constants.copFormat(empresa.pedido_minimo)}
                                        {/* {" " + new Intl.NumberFormat("es-CO", {
                                            style: "currency",
                                            currency: "COP"
                                        }).format(empresa.pedido_minimo )} */}
                                    </p>
                                </div>

                            </div>
                        ) : (
                            <button type="submit" className="btn-large" onClick={() => handlePedido()}>Enviar pedido</button>
                        )}
                    </React.Fragment>
                )}

                {empresa.sucursal.texto_liquidacion ? (
                    <div className="carrito-informacion">
                        <div>
                            <img src={error} alt="icon plus" width="20px" height="20px"></img>
                        </div>
                        <div className="contenido">
                            <text dangerouslySetInnerHTML={{ __html: empresa.sucursal.texto_liquidacion_web.replace("&lt;", "<").replace("&gt;", ">") }}></text>
                        </div>
                    </div>
                ) : null}
            </div>

            <Tabbar item={5} cantidad={cantidad} />
            <EncuestaModal encuesta={encuesta} codCliente={empresa.sucursal.codigo} codProveedor={empresa.codigo_empresa} codEmpresa={empresa.codigo_empresa} enviarPedido={handlePedido} />
            <ObservacionesModal codEmpresa={empresa.codigo_empresa} enviarPedido={enviarPedido} />

        </React.Fragment>


    )
}



export default withRouter(Carrito);