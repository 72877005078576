export default (state, action) => {
    switch (action.type) {
      case "ADD_CARRITO":
        return {
          ...state,
          carrito: [...state.carrito, action.payload]
        };
      default:
        return state;
    }
};