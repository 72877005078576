import React, { useState, useEffect } from 'react';

import Navbar from '../navbar/Navbar';
import Tabbar from '../navbar/Tabbar';
import Categoria from '../navbar/Categoria';
import Producto from './Producto';

import * as Constants from '../../constants/global';

const Pedido = () => {
    
    let empresa = JSON.parse(localStorage.getItem('empresa'));

    const [search, setSearch] = useState();
    const [categoria, setCategoria] = useState();
    const [totalNavbar, setTotalNavbar] = useState();
    const [cantidad, setCantidad] = useState();

    const handleCategoria = item => {
        setCategoria(item);
    }

    const handleCantidad = () => {
        getCantidad();
    }

    const getCantidad = () => {

        Constants.calcularDescuento()
        let carrito = JSON.parse(localStorage.getItem(empresa.codigo_empresa)) || {};

        setCantidad(Object.keys(carrito).length)

        let iva = 0;
        let total = 0;
        let descuentoTotal = 0;

        Object.keys(carrito).map((item, i) => {

            let subtotal = (carrito[item]["cantidad"] * carrito[item]["precio"]);
            let descuento = subtotal * (carrito[item]["porcentajeDescuento"] ? carrito[item]["porcentajeDescuento"] / 100 : 0 )
            iva += ( (subtotal - descuento) * (carrito[item]["iva"] ? carrito[item]["iva"] / 100 : 0) );

            descuentoTotal += descuento
        })

        Object.keys(carrito).map((item, i) => {
            total += carrito[item]["cantidad"] * carrito[item]["precio"];
        })

        setTotalNavbar(total - descuentoTotal + iva);
    }

    useEffect(() => {
        getCantidad();
    }) 

    return (
        <React.Fragment>
            <div className="navbar">
                <div className="navbar-header">
                    <Navbar item={1} cantidad={cantidad} totalNavbar={totalNavbar}/>
                    <div className="input-search">
                        <input 
                            type="text" 
                            placeholder="Buscar producto..."
                            name="search"
                            id="search"
                            onChange={(e) => setSearch(e.target.value)}
                            value={search}>
                        </input>
                    </div>
                </div>
                <Categoria handleCategoria={handleCategoria}/>
            </div>
            
            <Producto tipo={2} categoria={categoria} search={search} handleCantidad={handleCantidad} />

            <Tabbar item={1} cantidad={cantidad}/>
        </React.Fragment>
    )
}

export default Pedido;