import React, { createContext, useReducer } from "react";
import AppReducer from "./AppReducer";

// Initial state
const initialState = {
    carrito: []
};

// Create contex
export const AppContext = createContext(initialState);

// Provider component
export const AppProvider = ({ children }) => {
    const [state, dispatch] = useReducer(AppReducer, initialState);
  
    // Actions
    async function addCarrito(producto) {
        dispatch({
            type: "ADD_CARRITO",
            payload: producto
        });
    }
  
    return (
      <AppContext.Provider
        value={{
            carrito: state.carrito,
            addCarrito
        }}
      >
        {children}
      </AppContext.Provider>
    );
  };