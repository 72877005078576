import React, { useState } from 'react';
// import { NetworkInfo } from "react-native-network-info";

import { withRouter } from "react-router-dom";
import * as Constants from '../../constants/global';
import axios from 'axios';
import Progress from '../util/Progress';

import logo from '../../assets/img/logo.png';
import logo_login from '../../assets/img/logo_login.png';
import maqueta from '../../assets/img/maqueta.jpg';
import subtitulo from '../../assets/img/subtitulo.png';

import Fondo from '../../assets/img/Fondo_3000x3000.png';

import LoginModal from '../modal/LoginModal';

import RegistroModal from '../modal/RegistroModal';
import CodigoActivacionModal from '../modal/CodigoActivacionModal';
// import RecuperacionClaveModal from '../modal/RecuperacionClaveModal';


const Login = () => {

    const [nit, setNit] = React.useState('');
    const [loader, setLoader] = React.useState(false);
    const [error, setError] = React.useState("");
    const [correoCodigo, setCorreoCodigo] = React.useState("");
    const [celularAyuda, setCelularAyuda] = React.useState("");
    // const [ipv4, setIpv4] = React.useState("");

    const [validacion, setValidacion] = useState({Telefonos: []});
    const [empresa, setEmpresa] = React.useState("");

    // const publicIp = require('public-ip');
 
    // const getIpv4 = async () => {
    //     setIpv4(await publicIp.v4());        
    // };

    // useEffect(() => {
        // setNit("890900608");
        // getIpv4();
    // })

    // CONSULTA LAS EMPRESAS
    const handleSubmit = event => {
        // if(nit.length >= 8){
            setLoader(true);
            let url = Constants.URL_BASE + Constants.WSEMPRESA + "?nit=" + nit;

            axios.get(url)
            .then(resEmpresa => {
                if(resEmpresa.data.length > 0){

                    localStorage.setItem("nit", nit);

                    setEmpresa(resEmpresa.data)

                    url = Constants.URL_BASE + Constants.WSVALIDARCLIENTE;

                    let data = {
                        "Nit": nit,
                        // "DeviceId": ipv4,
                        "DeviceType": "Web"
                    };

                    axios.post(url, data)
                    .then(res => {
                        console.log(res);
                        setLoader(false);

                        setValidacion(res.data);

                        if (res.data.Correos.length > 0) {
                            setCorreoCodigo(res.data.Correos[0].substring(0,4) + "*".repeat(res.data.Correos[0].indexOf("@",0)-4) + res.data.Correos[0].substring(res.data.Correos[0].indexOf("@",0),res.data.Correos[0].length))
                        }

                        if (res.data.Telefonos.length > 0) {
                            setCelularAyuda("*"+res.data.Telefonos[0].substring(res.data.Telefonos[0].length - 4, res.data.Telefonos[0].length))
                        }

                        if(res.data.Activo == 0){
                            document.querySelector(".modal-registro").classList.toggle("show-modal");
                        } else {
                            document.querySelector(".modal-login").classList.toggle("show-modal");
                        }
                    });


                }else{
                        setLoader(false);
                        setError("No se encontraron coicidencias con este NIT.");
                    }
                });
        // }else{
        //     setLoader(false);
        //     setError("El NIT debe contener al menos (8) digitos.");
        // }
        
        event.preventDefault();
    }

    setTimeout(function(){
        if(document.querySelector(".login-content")){
            document.querySelector(".login-content").classList.add("show");
        }
    }, 3000);
    
    // VISTAS
    return (
        <div className="login">
            <div className="login-content">
                <div className="splash" id="splash">
                {/* <div className="splash" id="splash" style={{backgroundImage: `url(${Fondo})`}}> */}
                    <div className="logo-img">
                        {/* <img src={logo} alt="logo app"></img> */}
                    </div>
                    <div className="maqueta-img">
                        <img src={maqueta} alt="logo app"></img>
                    </div>
                    <div className="footer">
                        <div className="footer-text">
                            {/* <p>Celuweb desde hace varios meses venia trabajando en la siguiente iniciativa, y quire poner a disposición de sus clientes como una contingencia la plataforma Emart</p> */}
                        </div>
                        <div className="footer-img">
                            {/* <img src={subtitulo} alt="logo app"></img> */}
                        </div>
                    </div>
                </div>
                <div className="login-form" id="login-form">
                    <div className="logo-img">
                        <img  src={logo_login} alt="logo app"></img>
                    </div>
                    { loader ? <Progress color={"#007daf"} /> : (
                        <form onSubmit={handleSubmit}>
                            <div className="form-control">
                                <label>NIT:</label>
                                <input 
                                    type="number"
                                    placeholder="NIT sin digito de verificación"
                                    name="nit"
                                    id="nit"
                                    onChange={e => setNit(e.target.value)}
                                    value={nit}>
                                </input>
                                { error.length > 0 ? (
                                    <span className="error">
                                        <p>{error}</p>
                                    </span>
                                ) : null }
                                
                            </div>
                            <button type="submit" className="btn-large">Ingresar</button>
                        </form>
                    )}
                    {/* { validacion.Telefonos ?  */}
                    <RegistroModal empresa={empresa} nit={nit} validacion={validacion} correoCodigo={correoCodigo} celularAyuda={celularAyuda} />
                     {/* :( null ) } */}

                    <CodigoActivacionModal validacion={validacion} empresa={empresa} />

                    {/* { validacion ?  */}
                    <LoginModal nit={nit} empresa={empresa} correoCodigo={correoCodigo} />
                     {/* :( null ) } */}

                    {/* <RecuperacionClaveModal nit={nit} /> */}

                    <div className="logo-footer">
                        {/* <img  src={logo_login} alt="logo app"></img>
                        <img  src={logo_login} alt="logo app"></img> */}
                    </div>

                </div>
            </div>
        </div>
        // <div className="login">
        //     <div className="login-content">
        //         <div className="logo-img">
        //             <img src={logo} alt="logo app"></img>
        //         </div>
        //         <div>
        //             { loader ? <Progress color={"#ffffff"} /> : (
        //                 <form onSubmit={handleSubmit}>
        //                     <div className="form-control">
        //                         <label>NIT:</label>
        //                         <input 
        //                             type="number"
        //                             placeholder="NIT sin digito de verificación"
        //                             name="nit"
        //                             id="nit"
        //                             onChange={e => setNit(e.target.value)}
        //                             value={nit}>
        //                         </input>
        //                     </div>
        //                     <button type="submit" className="btn-large">Ingresar</button>
        //                 </form>
        //             )}
        //             <div className="footer">
        //                 <p><b>Powered by Celuweb</b></p>
        //                 <p>Version 1.0.0</p>
        //             </div>
        //         </div>
        //     </div>
        // </div>
    )
}


export default withRouter(Login);