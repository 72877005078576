import React from 'react';


const Progress = (props) => {
    return (
        <div className="progress">
            <div className="loader">
                <svg className="circular" >
                <circle className="path" style={{stroke: props.color}} cx="50" cy="50" r="20" fill="none" strokeWidth="5" strokeMiterlimit="10"></circle>
                </svg>
            </div>
        </div>
    )
}


export default Progress;