import React, { useState, useEffect, useContext } from 'react';

import * as Constants from '../../constants/global';
import axios from 'axios';

//import { AppContext } from '../../context/AppState';

import Progress from '../util/Progress';

import {ReactComponent as Minus} from '../../assets/icon/minus.svg';
import {ReactComponent as Plus} from '../../assets/icon/plus.svg';

import noimage from '../../assets/img/noimage.png';

const Producto = (props) => {

    const [carrito, setCarrito] = useState();
    const [loader, setLoader] = React.useState(false);
    const [productos, setProductos] = useState();

    // const [cantidad, setCantidad] = useState();
    let empresa = JSON.parse(localStorage.getItem('empresa'));

    // TRAE TODOS LOS PRODUCTOS
    const loadProductos = async (tipo) => {
        let url = Constants.URL_BASE +
                    Constants.WSPRODUCTO +
                    "?origen=" + empresa.codigo_empresa + 
                    "&cliente=" + empresa.sucursal.codigo;

        if(props.search){
            url += "&search=" + props.search;
        } else {
            if(props.categoria){
                url += "&ca=" + props.categoria.codigo;
            }
        }

        if(tipo == 1){
            if(productos){
                if(productos.now < productos.next){
                    url += "&page=" + productos.next;
                }
            }
        }
        

        axios.get(url)
        .then(res => {
            setLoader(false);
            let array = res.data;
            
            if(tipo == 1){
                if(productos){
                    if(productos.result){
                        let combine = productos.result.concat(array.result);
                        array.result = combine;
                    }
                }
            }
            
            setProductos(array);
        });

        props.handleCantidad();
    }

    const cargarMas = () => {
        loadProductos(1);
    }

    // AUMENTA LA CANTTIDAD
    const handleMas = item => {    
        if(carrito[item.codigo]){
            carrito[item.codigo]["cantidad"] += 1;
        }else{
            carrito[item.codigo] = {
                nombre: item.nombre,
                precio: parseFloat(item.precio),
                iva: parseInt(item.iva),
                cantidad: 1
            }
        }
        localStorage.setItem(empresa.codigo_empresa, JSON.stringify(carrito));
        setCarrito(carrito);
        loadProductos(0);
    }

    const handleMenos = item => {
        if(carrito[item.codigo]){
            if(carrito[item.codigo]["cantidad"] == 1){
                delete carrito[item.codigo];
            }else{
                carrito[item.codigo]["cantidad"] -= 1;
            }
        }
        localStorage.setItem(empresa.codigo_empresa, JSON.stringify(carrito));
        setCarrito(carrito);
        loadProductos(0);
    }

    const handleChangeCantidad = (item, valor) => {
        if(valor <= 0){
            delete carrito[item.codigo];
        }else{
            if(carrito[item.codigo]){
                carrito[item.codigo]["cantidad"] = parseInt(valor);
            }else{
                carrito[item.codigo] = {
                    nombre: item.nombre,
                    precio: item.precio,
                    cantidad: parseInt(valor)
                }
            }
        }
        localStorage.setItem(empresa.codigo_empresa, JSON.stringify(carrito));
        setCarrito(carrito);
        loadProductos();
    }

    // SE EJECUTA CUANDO CAMBIA CATEGORIA O BUSQUEDA
    useEffect(() => {

        setLoader(true);
        setCarrito(JSON.parse(localStorage.getItem(empresa.codigo_empresa)) || {});
        if(props.categoria){
            loadProductos(0);
        }
        cargarBanners(empresa.url, empresa.path, empresa.num_empresa)
    }, [props.categoria, props.search])


    const cargarBanners = (urlEmpresa, pathEmpresa, codEmpresa) => {
        let url = Constants.URL_BASE +
            Constants.WSBANNERS +
            "?numEmpresa=" + codEmpresa;

        axios.get(url)
        .then(res => {
            setLoader(false);
            localStorage.setItem("bannerImages", JSON.stringify(res.data));
        });
    }

    const getUrlBanner = () => {

        let listadoBanners = JSON.parse(localStorage.getItem("bannerImages"));
        let categoria = props.categoria;

        let urlImage = "";

        if (categoria){

            let dataImagen = listadoBanners.find(element => element.CodigoCategoria == categoria.codigo); 
            
            urlImage = (dataImagen ? dataImagen.Imagen : "")
        }
        
        return urlImage

    }

    // VISTA
    return (
        <div className="main">

            <div className="banner-ramo">
                {/* <img id="imagen-banner-ramo" src={getUrlBanner()}></img> */}
                {/* <img id="imagen-banner-ramo" src="https://firebasestorage.googleapis.com/v0/b/harinerab2c.appspot.com/o/banner_harinera.png?alt=media&token=867a0649-10e3-4467-b58d-5e3d2c611073"></img> */}
            </div>

            <div className='pedido-list padding-banner'>
                <div className='pedido-list'>
                    { loader ? <Progress color={"#007daf"} /> : (
                        <React.Fragment>  
                            
                                {productos ? (
                                    <React.Fragment>
                                        { props.tipo == 2 ? (
                                            <div className="product-list">
                                                {productos.result.map((item, i) => (
                                                    <div className="item" key={i}>
                                                        <p className="nombre"><b>{item.nombre}</b></p>
                                                        <p className="codigo"><b>Codigo:</b> {item.codigo}
                                                        {/* - <b>EAN:</b> {item.ean} */}
                                                        </p>
                                                        <p className="precio"> { Constants.copFormat(item.precio * (1 + ((item.iva ? item.iva : 0) / 100))) }
                                                            {/* {new Intl.NumberFormat("es-CO", {
                                                                style: "currency",
                                                                currency: "COP"
                                                            }).format(item.precio * (1 + ((item.iva ? item.iva : 0) / 100)))} */}
                                                        </p>
                                                        <div className="action">
                                                            { carrito[item.codigo] ? (
                                                                <div className="controls">
                                                                    <button role="button" onClick={() => handleMenos(item)}>
                                                                        {/* <img src={minus} alt="icon minus" width="15px" height="15px"></img> */}
                                                                        <Minus className="svg-una-capa" style={{width:"15px", height:"15px"}}/>
                                                                    </button>
                                                                    <input type="number" 
                                                                        onChange={e => handleChangeCantidad(item, e.target.value)}
                                                                        value={carrito[item.codigo].cantidad}/>
                                                                    <button role="button" onClick={() => handleMas(item)}>
                                                                        {/* <img src={plus} alt="icon plus" width="15px" height="15px"></img> */}
                                                                        <Plus className="svg-una-capa" style={{width:"15px", height:"15px"}}/>
                                                                    </button>
                                                                </div>
                                                            ) : (
                                                                <button className="agregar" onClick={() => handleMas(item)}>Agregar</button>
                                                            )}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <div className="product-card">
                                                {productos.result.map((item, i) => (
                                                    <div className="card" key={i}>
                                                        <img 
                                                            width="250"
                                                            className="img-product"
                                                            src={'https://harinera-nes.celuwebcloud.com/SyncEmart/Imgs/' + empresa.num_empresa + '/'+ item.codigo.toString().trim() + '.jpg'}

                                                            // src={'https://fuerzaventas3.celuwebdev.com/HARINERASAP/SyncEmart/Imgs/' + empresa.num_empresa + '/'+ item.codigo.toString().trim() + '.jpg'}
                                                            onError={(e)=>{e.target.onerror = null; e.target.src=noimage}}>
                                                        </img>
                                                        <p className="nombre"><b>{item.nombre}</b></p>
                                                        <p className="codigo"><b>Codigo:</b> {item.codigo}</p>
                                                        {/* <p className="codigo"><b>EAN:</b> {item.ean}</p> */}
                                                        <p className="precio"> {Constants.copFormat(item.precio * (1 + ((item.iva ? item.iva : 0) / 100)))}
                                                            {/* {new Intl.NumberFormat("es-CO", {
                                                                style: "currency",
                                                                currency: "COP"
                                                            }).format(item.precio * (1 + ((item.iva ? item.iva : 0) / 100)))} */}
                                                        </p>
                                                        <div className="action">
                                                            { carrito[item.codigo] ? (
                                                                <div className="controls">
                                                                    <button role="button" onClick={() => handleMenos(item)}>
                                                                        {/* <img src={minus} alt="icon minus" width="15px" height="15px"></img> */}
                                                                        <Minus className="svg-una-capa" style={{width:"15px", height:"15px"}}/>
                                                                    </button>
                                                                    <input type="number" 
                                                                        onChange={e => handleChangeCantidad(item, e.target.value)}
                                                                        value={carrito[item.codigo].cantidad}/>
                                                                    <button role="button" onClick={() => handleMas(item)}>
                                                                        {/* <img src={plus} alt="icon plus" width="15px" height="15px"></img> */}
                                                                        <Plus className="svg-una-capa" style={{width:"15px", height:"15px"}}/>
                                                                    </button>
                                                                </div>
                                                            ) : (
                                                                <button className="agregar" onClick={() => handleMas(item)}>Agregar</button>
                                                            )}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}

                                        <div className="load-mas">
                                            {productos.now < productos.next ? (
                                                <button onClick={() => cargarMas()}>Cargar mas</button>
                                            ) : null }
                                        </div>
                                        
                                    </React.Fragment>
                                ) : null}
                        </React.Fragment>
                    )}
                </div>
            </div>
        </div>

    )
}

export default Producto;